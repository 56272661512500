import Vue from 'vue';
import axios from 'axios';
import store from '../store/index.js'
import {
	ElMessage
} from 'element-plus';
import router from '../router/index.js';
let host = 'https://xwxw.work'
let baseURL = '/';
const service = axios.create({
	baseURL: baseURL,
	timeout: 5000
});
// 请求超时 请求头
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
	config => {
		//console.log(123,token);
		return config;
	},
	error => {
		return Promise.error(error);
	})

// 响应拦截器

axios.interceptors.response.use(
	response => {
		if (response.data.code == 1) {
			return Promise.resolve(response);
		}else if (response.data.code === -1) {
			store.commit('exit')
			// setTimeout(() => {
			// 	location.reload(function() {
			// 		router.replace({
			// 			path: '/index'
			// 		});
			// 	})
			// }, 1000);
		} else {
			ElMessage.error(response.data.info);
			return Promise.resolve(response);
		}
	},
	// 服务器状态码不是200的情况    
	error => {
		if (error.response.status) {
			switch (error.response.status) {
				// 401: 未登录                
				case 401:
					// Message.error({
					// 	 message: '请登录!'
					//  })
					// localStorage.removeItem('info');
					// setTimeout(() => {
					// 	location.reload(function(){
					// 		router.replace({
					// 		    path: '/login',// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
					// 		    query: {
					// 		        redirect: router.currentRoute.fullPath
					// 		    }
					// 		});
					// 	})
					// }, 1000);
					break;
				case 403:
					ElMessage.error({
						message: '登录过期，请重新登录'
					});
					// 清除token                    
					localStorage.removeItem('info');
					setTimeout(() => {
						location.reload(function() {
							router.replace({
								path: '/login', // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
								query: {
									redirect: router.currentRoute.fullPath
								}
							});
						})
					}, 1000);
					break;
					// 404请求不存在                
				case 404:
					ElMessage.error('地址不存在');
					break;
					// 其他错误，直接抛出错误提示                
				default:
					ElMessage.error('服务器链接失败！');
			}
			return Promise.reject(error.response);
		}
	}
);

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(host + url, {
				params: params
			})
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/**
 * 封装patch请求
 * @param url
 * @param params
 * @returns {Promise}
 */
export function patch(url, params) {
	return new Promise((resolve, reject) => {
		axios.patch(url, params)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

/**
 * put 请求
 * @param  url 
 * @param  params 
 */
export function put(url, params) {
	return new Promise((resolve, reject) => {
		axios.put(url, params)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}