import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'

const app = createApp(App);
installElementPlus(app)
app.config.globalProperties.$onLaunched = new Promise(resolve => {
    app.config.globalProperties.$isResolve = resolve;
})

app.use(store);
app.use(router);
app.mount('#app')

