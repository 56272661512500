<template>
	<router-view />
</template>

<script>
	export default {
		data() {

		},
		async created() {

			//console.log(this.$store.state,'state');
		},
		methods: {

		}
	};
</script>

<style>
	@import "./assets/css/main.css";

	.loading-box .el-loading-spinner {
		left: 50%;
		margin-left: -15%;
		border-radius: 5px;
		background-color: #fff;
		padding: 20px 0;
		width: 30%;
	}
</style>