import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from "vue-router";

const routes = [
	{
		path: '/',
		redirect: '/index'
	},
	{
		path: "/index",
		name: "index",
		meta: {
			title: ''
		},
		component: () => import("../views/index.vue")
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
});

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title}`;
	next();
});
export default router;
